<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="7">
          <h1>
            Mandadito de
            <span class="font-weight-light"> {{ business.shortName }}</span>
          </h1>
        </v-col>

        <v-col cols="12" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            scrollable
            max-width="300px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0 ml-2"
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                >
                  Filtrar
                </v-btn>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="1" class="text-right d-flex flex-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="rgba(0, 0, 0, 0.6)"
                fab
                v-on="on"
                class="ml-2"
                dark
                small
                :elevation="0"
                :loading="loading"
                @click="reportsDialog = true"
              >
                <v-icon x-large dark>fa-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar .xlsx</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" :class="{ 'table-mobile-r': isMobile }">
          <v-data-table
            :headers="headers"
            :items="filteredLogs"
            :items-per-page="10"
            :loading="loading"
            sort-by="progressPlacedAt"
            :sort-desc="true"
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            :search="search"
            item-key=".key"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template v-slot:top> </template>

            <template v-slot:[`item.progressCurrentStatus`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="getColor(item.progressCurrentStatus)"
                    dark
                    v-on="on"
                    @click="showChangeStatus(item)"
                    style="
                      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                      cursor: pointer;
                    "
                    >{{ item.progressCurrentStatus | statusFilter }}
                  </v-chip>
                </template>
                <span>Clic para cambiar el estado de la orden</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.deliveryCost`]="{ item }">
              {{ item.deliveryCost | currency }}
            </template>

            <template v-slot:[`item.prizes`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <i
                    v-on="on"
                    class="fas fa-gift"
                    style="font-size: 20px; color: #FF1744; cursor: pointer"
                    v-if="item.prizes && item.prizes.length"
                  />
                </template>
                <div>
                  <span>Premios en esta order</span>
                  <p
                    style="color: white"
                    class="font-weight:bold"
                    v-for="(prize, id) of item.prizes"
                    :key="id"
                  >
                    - {{ prize.description }}
                  </p>
                </div>
              </v-tooltip>
            </template>

            <template v-slot:[`item.orderSubTotal`]="{ item }">
              {{ item.orderSubTotal | currency }}
            </template>

            <template v-slot:[`item.productsSubTotal`]="{ item }">
              {{ item.productsSubTotal | currency }}
            </template>

            <template v-slot:[`item.progressTotalFiltered`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <p style="cursor: pointer" v-on="on">
                    {{ item.progressTotalFiltered }}
                  </p>
                </template>
                <span>Tiempo orden Colocada - Entregada</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.progressCompletedAtFiltered`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <p style="cursor: pointer" v-on="on">
                    {{ item.progressCompletedAtFiltered }}
                  </p>
                </template>
                <span>Tiempo orden Entregada - Completada</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.orderChannel`]="{ item }">
              <v-img
                v-if="
                  item.orderChannel == 'ocho_cargo_api' ||
                  item.orderChannel == 'ocho_cargo'
                "
                width="50"
                :src="cargo"
              ></v-img>
            </template>

            <template v-slot:[`item.orderPaymentMethod`]="{ item }">
              <v-chip
                :color="colorPaymentMethod(item.orderPaymentMethod)"
                :dark="item.orderPaymentMethod !== 'card'"
                style="
                  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                "
              >
                {{
                  (item.orderPaymentMethod2
                    ? item.orderPaymentMethod2
                    : item.orderPaymentMethod) | orderPaymentMethodFilter
                }}
              </v-chip>
            </template>

            <template v-slot:[`item.timeFromPlacedStatus`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-show="
                      item.progressCurrentStatus === 'placed' &&
                      item.timeFromPlacedStatus >= 3
                    "
                    class="mx-1"
                    fab
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    x-small
                    :elevation="0"
                  >
                    <v-icon color="red" v-on="on" x-large>
                      fas fa-exclamation-circle
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ item.timeFromPlacedStatus }} minutos desde que el cliente
                  colocó esta orden
                </span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.isRaining`]="{ item }">
              <v-tooltip v-if="item.isRaining" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    v-on="on"
                    :dark="$vuetify.theme.dark"
                    x-small
                    :elevation="0"
                  >
                    <v-icon color="#858585" v-on="on" x-large>
                      fas fa-cloud-rain
                    </v-icon>
                  </v-btn>
                </template>
                <span> Orden colocada con cargo extra por lluvia. </span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.shortId`]="{ item }">
              <v-tooltip v-if="item.shortId" right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.shortId)"
                  >
                    {{ item.shortId }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block; text-transform: capitalize"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.userName)"
                  >
                    {{ item.userName }}
                  </v-chip>
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.progressPlacedAtFiltered`]="{ item }">
              <span class>{{ item.progressPlacedAtRelative }}</span>
              <br />
              <span
                :class="
                  $vuetify.theme.dark
                    ? 'grey--text'
                    : 'grey--text text--darken-1'
                "
              >
                {{ item.progressPlacedAtFiltered }}
              </span>
            </template>

            <template v-slot:[`item.orderType`]="{}">
              <!-- <v-chip
                v-if="item.orderType == 'pickUp'"
                color="yellow"
                style="
                  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                "
              >
                {{ item.orderType | orderTypeName }}
              </v-chip> -->
              <p class="ml-2">
                <!-- {{ item.orderType | orderTypeName }} -->
                Mandadito
              </p>
            </template>

            <template v-slot:[`item.businessFleetType`]="{ item }">
              <span v-if="item.deliveryType == 'errand'"> Ocho </span>
              <span v-else class>{{
                item.businessFleetType | fleetTypeName
              }}</span>
            </template>
            <template v-slot:[`item.orderTotal`]="{ item }">
              <span class>{{ item.orderTotal | currency }}</span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-4">
                <errand-details
                  v-if="item.orderType == 'errand' || item.orderType == 'cargo'"
                  :item="item"
                />
              </td>
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- CHANGE ORDER STATUS DIALOG -->
    <v-dialog
      max-width="700px"
      v-if="reportsDialog"
      persistent
      v-model="reportsDialog"
    >
      <reports
        :businessId="selectedCorporative['.key']"
        :date="date"
        @cancel="reportsDialog = false"
      />
    </v-dialog>

    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
  </div>
</template>
  <script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

import { mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import "moment-duration-format";
import errandDetails from "@/views/c19/errand-details";
import reports from "./report";

export default {
  name: "cargo-errand",
  components: {
    lottie: Lottie,
    errandDetails,
    reports,
  },
  mixins: [lottieSettings],
  data() {
    return {
      modal: null,
      isMobile: screen.width <= 800 ? true : false,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .startOf("month")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],

      business: {},
      errands: [],
      cargo: require("@/assets/apps/cargo.svg"),

      dialog: false,
      snackbar: false,
      snackbarText: null,
      loading: true,

      expanded: [],

      totalOrderTime: 0,
      reportsDialog: false,
      searchableTags: [
        { name: "placed", color: "lime darken-1" },
        { name: "accepted", color: "green" },
        { name: "enRoute", color: "amber" },
        { name: "onDelivery", color: "orange" },
        { name: "delivered", color: "cyan" },
        { name: "completed", color: "blue" },
        { name: "canceled", color: "grey" },
        { name: "pendingValidation", color: "purple" },
        { name: "rejected", color: "red" },
      ],
      headers: [
        {
          text: "Estado",
          value: "progressCurrentStatus",
          align: "center",
          active: true,
          class: "nowrap",
        },
        {
          text: "Ciudad",
          value: "cityName",
          active: true,
          width: "150px",
        },
        {
          text: "Estado del Pago",
          value: "orderPaymentStatus",
          align: "center",
          active: true,
          class: "nowrap",
        },
        {
          text: "Forma del Pago",
          value: "orderPaymentMethod",
          align: "center",
          active: true,
          class: "nowrap",
        },
        {
          text: "Order ID",
          value: "shortId",
          align: "center",
          active: true,
          class: "nowrap",
        },
        {
          text: "Cliente",
          value: "userName",
          active: true,
          class: "nowrap",
        },

        {
          text: "Negocio",
          value: "businessName",
          width: "200px",
          active: true,
          class: "nowrap",
        },

        {
          text: "Tipo de delivery",
          value: "orderType",
          active: true,
          class: "nowrap",
        },

        {
          value: "orderChannel",
          active: true,
          text: "Origen",
          class: "nowrap",
          width: "150px",
        },
        {
          text: "Costo Envío",
          value: "deliveryCost",
          active: true,
          class: "nowrap",
        },

        {
          text: "Monto Total",
          value: "orderTotal",
          align: "end",
          active: true,
          class: "nowrap",
        },
        {
          text: "Aceptada",
          value: "progressAcceptedAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Asignada",
          value: "progressPickupStartedAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "En camino",
          value: "progressDeliveryStartedAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Entregada",
          value: "progressDeliveryAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Completada",
          value: "progressCompletedAtFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Tiempo Total",
          value: "progressTotalFiltered",
          filterable: false,
          active: true,
          class: "nowrap",
        },
        {
          text: "Creada",
          value: "progressPlacedAtFiltered",
          filterable: false,
          width: "150",
          active: true,
          class: "nowrap",
        },
      ],
    };
  },
  filters: {
    toHours(seconds) {
      var duration = moment.duration(seconds, "seconds");
      var formatted = duration.format("hh:mm:ss");
      return formatted;
    },
    formatNumber: function (value) {
      if (!value) return 0;
      return new Intl.NumberFormat().format(value);
    },
    orderTypeName: function (value) {
      let deliveryType = {
        regular: "Regular",
        pickUp: "Recogida",
        regularPickUp: "Recogida regular",
        none: "N/A",
        errand: "Mandadito",
        airTime: "N/A",
        "": "N/A",
        cargo: "Mandadito",
        dineIn: "Comer en restaurante",
        room: "Habitación",
      };

      return deliveryType[value];
    },
    fleetTypeName: function (value) {
      let fleetType = {
        ocho: "Ocho",
        mixed: "Mixto",
        own: "Propia",
        "": "N/A",
      };

      return fleetType[value];
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },

    statusFilter: function (status) {
      switch (status) {
        case "placed":
          return "Colocada por el Cliente";
        case "accepted":
          return "Aceptada por el Comercio";
        case "enRoute":
          return "Chavo en Camino al Comercio";
        case "onDelivery":
          return "Chavo en Camino al Cliente";
        case "delivered":
          return "Entregada al Cliente";
        case "completed":
          return "Orden Completada";
        case "canceled":
          return "Cancelado por Cliente";
        case "pendingValidation":
          return "Requiere Validación";
        case "rejected":
          return "Cancelado por administración";
        default:
          return "Desconocido";
      }
    },

    orderPaymentMethodFilter(method) {
      switch (method) {
        case "card":
          return "Tarjeta";
        case "cash":
          return "Efectivo";
        case "credit":
          return "Al crédito";
        case "tengo":
          return "Tengo";
        case "tigoMoney":
          return "Tigo Money";
        case "dilo":
          return "Dilo";
        case "coupon":
          return "Cupón";
        case "opl":
          return "Ocho Pay";
        default:
          return "Desconocido";
      }
    },
  },
  watch: {
    async date(val) {
      if (val.length > 1) {
        let errandsCollection = db.collection(`ordersErrands`);

        this.loading = true;
        this.$store.commit("setSearch", "");

        this.errands = [];

        let errandsQuery = errandsCollection
          .where("businessId", "==", this.selectedCorporative[".key"])
          .where(
            "progressPlacedAt",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "progressPlacedAt",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          );

        await this.$binding("errands", errandsQuery);
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState(["selectedCorporative"]),

    search() {
      return this.$store.state.search;
    },

    today() {
      return new Date().toISOString().substr(0, 10);
    },

    filteredLogs() {
      this.totalOrderTime = 0;

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      const allItems = [...this.errands];

      let orders = allItems.map((x) => {
        //accumulator for total time for all orders
        if (x.progressPlacedAt && x.progressDeliveredAt) {
          let a = moment
            .unix(x.progressPlacedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          let seconds = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("X");
          this.totalOrderTime = this.totalOrderTime + Number(seconds);
        }

        x.progressPlacedAtFiltered = x.progressPlacedAt
          ? moment(x.progressPlacedAt.toDate())
              .tz("America/Tegucigalpa")
              .format("DD/MM, hh:mm A")
          : "";

        x.progressPlacedAtRelative = x.progressPlacedAt
          ? moment(x.progressPlacedAt.toDate()).fromNow()
          : "";

        if (x.progressAcceptedAt) {
          let a = moment
            .unix(x.progressPlacedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressAcceptedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressAcceptedAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }
        if (x.progressAcceptedAt && x.progressPickupStartedAt) {
          let a = moment
            .unix(x.progressAcceptedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressPickupStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          x.progressPickupStartedAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }
        if (x.progressDeliveryStartedAt && x.progressPickupStartedAt) {
          let a = moment
            .unix(x.progressPickupStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressDeliveryStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressDeliveryStartedAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }

        if (x.progressDeliveryStartedAt && x.progressDeliveredAt) {
          let a = moment
            .unix(x.progressDeliveryStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressDeliveryAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }
        if (x.progressDeliveredAt && x.progressCompletedAt) {
          let a = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          let b = moment
            .unix(x.progressCompletedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressCompletedAtFiltered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }
        if (x.progressDeliveredAt && x.progressPlacedAt) {
          let a = moment
            .unix(x.progressPlacedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          let b = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          let seconds = moment(b, "DD/MM/YYYY HH:mm:ss").diff(
            moment(a, "DD/MM/YYYY HH:mm:ss"),
            "seconds"
          );

          x.progressTotalFiltered = this.getMinutes(seconds);
        }

        if (x.progressPickupStartedAt && x.progressDeliveredAt) {
          let a = moment
            .unix(x.progressPickupStartedAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");

          let b = moment
            .unix(x.progressDeliveredAt.seconds)
            .format("DD/MM/YYYY HH:mm:ss");
          x.progressPickupDelivered = moment
            .utc(
              moment(b, "DD/MM/YYYY HH:mm:ss").diff(
                moment(a, "DD/MM/YYYY HH:mm:ss")
              )
            )
            .format("mm:ss");
        }

        x.orderTotalFiltered = x.orderTotal
          ? formatter.format(x.orderTotal)
          : formatter.format(0);
        x.updatedAtRelative = x.updatedAt
          ? moment(x.updatedAt.toDate()).fromNow()
          : "";

        return x;
      });

      // console.log(orders);
      return orders;
    },
  },
  methods: {
    // ...mapActions(["updateCity"]),

    getMinutes(seconds) {
      if (!seconds) {
        return "00:00";
      }

      let minutes = (seconds / 60).toFixed(2).toString().split(".");
      let calSecond = Number.parseInt((minutes[1] / 100) * 60);

      return `${minutes[0].toString().padStart(2, 0)}:${calSecond
        .toString()
        .padStart(2, 0)}`;
    },

    colorPaymentMethod(method) {
      switch (method) {
        case "card":
          return "rgba(0, 0, 0, 0.1)";
        case "cash":
          return "light-green";
        case "tengo":
          return "indigo";
        case "tigoMoney":
          return "#00377b";
        case "dilo":
          return "#f14244";
        case "coupon":
          return "purple";
        case "opl":
          return "#f06a25";
        default:
          return "black";
      }
    },

    title() {
      return "Selecciona las fechas a filtrar";
    },

    // editItem(item) {
    //   if (!item.couponId) {
    //     this.editedItem = Object.assign({}, item);
    //     this.dialog = true;
    //     return;
    //   }
    //   db.collection("coupons")
    //     .doc(item.couponId)
    //     .get()
    //     .then((res) => {
    //       this.editedItem = Object.assign({}, item);
    //       this.editedItem.couponRedeemCode = res.data().redeemCode;
    //       this.editedItem.couponDiscount =
    //         item.couponType == "percent"
    //           ? (item.orderOriginalSubTotal * item.couponAmount) / 100
    //           : item.couponType == "amount"
    //           ? item.couponAmount
    //           : item.couponType == "allFree"
    //           ? item.orderOriginalSubTotal
    //           : 0;
    //       this.dialog = true;
    //     });
    // },

    getColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      if (result) {
        return result.color;
      } else {
        return "gray";
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },
  },

  async mounted() {
    this.loading = true;
    this.$store.commit("setSearchTerm", "Mandados");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.$binding(
      "errands",
      db
        .collection("ordersErrands")
        .where("businessId", "==", this.selectedCorporative[".key"])
        .where(
          "progressPlacedAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "progressPlacedAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        )
    );

    await this.$binding(
      "business",
      db
        .collection("businessesCorporative")
        .doc(this.selectedCorporative[".key"])
    );

    this.loading = false;

    // await this.$binding(
    //   "cities",
    //   db
    //     .collection("cities")
    //     .where("active", "==", true)
    //     .where("deleted", "==", false)
    //     .orderBy("name", "asc")
    // );

    // this.loading = false;
    // this.getData();
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
};
</script>
  <style lang="scss" scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@import "@/_responsive.scss";

@include responsive(mobile) {
  .table-mobile-r {
    max-width: 100vw !important;
    padding: 0px;
    margin: 0px;
    min-width: 100vw !important;
    width: 100vw !important;
  }
  .v-data-table table {
    max-width: 100vw !important;
    min-width: 100vw !important;
    width: 100vw !important;
    border-spacing: 0;
    background-color: aqua !important;
  }
  .v-data-table__mobile-table-row {
    max-width: 100vw !important;
    min-width: 100vw !important;
    width: 100vw !important;
    padding: 0px;
    margin: 0px;
  }

  tbody {
    width: 100vw !important;
    max-width: 100vw !important;
    padding: 0px;
    margin: 0px;
    min-width: 100vw !important;
    flex-direction: column;
  }
  .v-data-table {
    max-width: 100vw !important;
  }
}
</style>
  